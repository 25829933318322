<template>
  <div class="subscriptionPage">
    <vuestic-widget v-if="user" :loading="loading">
      <template slot="header">
        <span class="title" v-if="user.stripe_active">Subscriptions</span>
      </template>
      <div v-if="currentStep == 1" class="cart-step">
        <h2>
          Hi, <span>{{this.agency && this.agency.name}}</span><br/>
          Please update your card information below to get access.
        </h2>
        <AddCreditCard 
          :loading="loadingSubscription"
          :plan="plan"
          button-text="Add payment method"
          v-on:done="handleAddedCard"
          v-on:failed="onCardFailed"
        ></AddCreditCard>
        <!-- <div class="useExistingPayment" v-if="user.has_payment_method" @click="getPaymentMethods">
          <a >Use existing Payment Method</a>
        </div> -->
      </div>
      <div class="listPaymentMethods" v-if="currentStep == 2">
        <div v-if="loadingPaymentMethod">
          <atom-spinner slot="loading" :animation-duration="1500" :size="80" color="#3578c6" />
        </div>
        <div v-if="!loadingPaymentMethod">
          <div class="paymentBox" v-for="payment in paymentMethods" :key="payment.id">
            <div class="cardBrand">{{ payment.brand }}</div>
            <div class="cardNumber">{{ payment.brand }} ****{{ payment.last_four }}</div>
            <div class="cardExpiration">Expires: {{ payment.exp_month }} / {{ payment.exp_year }}</div>
            <div class="actions">
              <a class="default" @click="setDefaultPaymentMethod(payment.id)" :disabled="loadingSetDefault">
                <atom-spinner v-if="loadingSetDefault" slot="loading" :animation-duration="1500" :size="14" color="#3578c6"/>
                Select
              </a>
            </div>
          </div>
        </div>
        <div class="useExistingPayment" @click="addPaymentMethod" v-if="!loadingPaymentMethod">
          <a>Add Payment Method</a>
        </div>
      </div>
      <div class="plans-page" v-if="currentStep == 3">
        <div class="listPlans">
          <b-tabs v-model="activeTabIndex" align="center" pills>
            <b-tab title="Monthly" :active="activeTabIndex === 0">
              <div class="boxPlan" v-for="plan in monthlyPlans" :key="plan.id" :class="{active: plan.id == user.stripe_plan}">
                <div class="name">{{ plan.label }}</div>
                <div class="price">${{ plan.amount }} / {{ plan.interval }}</div>
                <div v-if="plan.interval === 'year'" class="price price-small">(${{ (plan.amount_org / 12).toFixed(0) }} / month)</div>
                <button class="btn btn-success btnPurchase" @click="choosePlan(plan)" 
                  :disabled="plan.id == user.stripe_plan || loadingSubscription && selectedPlan && selectedPlan.id == plan.id">
                  <atom-spinner v-if="loadingSubscription && selectedPlan && selectedPlan.id == plan.id" 
                    slot="loading" :animation-duration="1500" :size="20" color="#fff" />
                  <span v-else>
                    {{ getPlanLabel(plan) }}
                  </span>
                </button>
                <div class="features">
                  <div v-for="(item, key) in formatMetadata(plan.metadata)" :key="key" v-if="showMeta(item)">
                    <p v-if="item.key != 'OneTime' && item.key != 'Promotion'">{{ item.label }}<b v-if="showMetaValue(item)">: {{ formatFeatureValue(item.value)}}</b></p>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Annually" :active="activeTabIndex === 1">
              <div class="boxPlan" v-for="plan in yearlyPlans" :key="plan.id" :class="{active: plan.id == user.stripe_plan}">
                <div class="name">{{ plan.label }}</div>
                <div class="price">${{ plan.amount }} / {{ plan.interval }}</div>
                <div v-if="plan.interval === 'year'" class="price price-small">(${{ (plan.amount_org / 12).toFixed(0) }} / month)</div>
                <button class="btn btn-success btnPurchase" @click="choosePlan(plan)" 
                  :disabled="plan.id == user.stripe_plan || loadingSubscription && selectedPlan && selectedPlan.id == plan.id">
                  <atom-spinner v-if="loadingSubscription && selectedPlan && selectedPlan.id == plan.id" 
                    slot="loading" :animation-duration="1500" :size="20" color="#fff" />
                  <span v-else>
                    {{ getPlanLabel(plan) }}
                  </span>
                </button>
                <div class="features">
                  <div v-for="(item, key) in formatMetadata(plan.metadata)" :key="key" v-if="showMeta(item)">
                    <p v-if="item.key != 'OneTime' && item.key != 'Promotion'">{{ item.label }}<b v-if="showMetaValue(item)">: {{ formatFeatureValue(item.value)}}</b></p>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div class="listEmpty" v-if="!loading && !plans.length">
          Don't have any plans
        </div>
        <div class="useExistingPayment" @click="addPaymentMethod" v-if="!loading && !user.stripe_active">
          <a >Try with Differnt Credit Card</a>
        </div>
        <div v-if="loading" class="loadingBox">
          <atom-spinner slot="loading" :animation-duration="1500" :size="80" color="#3578c6" />
        </div>
      </div>
    </vuestic-widget>

    <vuestic-modal
      :isOpen="isOpenModalConfirm"
      @ok="subscription"
      @cancel="closeModalConfirm"
      :closeOnOk="false"
      okText="Change"
      cancelText="Cancel"
      :processing="loadingSubscription"
    >
      <span slot="title" class="text-primary font-weight-bold">Change plan</span>
      <div class="pt-1">
        <p class="text-left">Are you sure you want to change plan to <b v-if="selectedPlan">{{ selectedPlan.name }}</b>?<br/><span v-if="showZapierAlert" class="text-danger">Your Zapier subscription will be cancelled.</span></p>
        <ul v-if="selectedPlan" class="mt-2 text-center list-unstyled">
          <li>Price: <b><span class="price-span" :class="{'line-through': couponInfo}">${{ formatPrice(selectedPlan.amount_org) }}</span><span v-if="couponInfo" class="price-span">${{ formatPrice(planPrice(selectedPlan)) }}</span> / <span>{{ selectedPlan.interval }}</span></b></li>
        </ul>
        <div v-if="couponEnabled" class="cardForm coupon-wrapper mt-2">
          <div class="row">
            <div v-if="couponInfo"  class="col-md-12 text-center mb-3">
              <span>( Coupon <b>{{ couponInfo.name }}</b> is applied )</span>
            </div>
            <div class="col-md-8">
              <input type="text" class="input" name="couponCode" v-model="couponCode" placeholder="Enter Discount Code"/>
            </div>
            <div class="col-md-4 d-flex justify-content-start">
              <button @click="applyCoupon" type="button" class="btn btn-default btn-success coupon-button" :disabled="processing || !couponCode">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="20" color="#FFF" />
                <span v-else>Apply</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenWgo"
      @cancel="onCancelWgoModal"
      :okShown="false"
      :cancelShown="false"
      :force="true"
      :large="true"
    >
      <span slot="title">White Glove Onboarding</span>
      <div class="px-1 py-3 text-center">
        <div class="h3 text-blue">
          <span class="font-weight-bold">WAIT!</span>
          <span class="font-weight-bold"> ✋ Before you get started... ✋</span>
        </div>
        <div class="font-weight-light" style="font-size: 25px">
          Do you want our team of experts to personally set up your account for you?
        </div>
        <div class="h5 font-weight-light mt-4">
          Instead of you having to spend your time setting up, our team will:
        </div>
        <ul class="text-left mt-4">
          <li>Provide a 30 minute introductory call and Q&A session</li>
          <li>Create your agency account</li>
          <li>Procure and connect your new SMS number</li>
          <li>Set up the detailed settings in your account</li>
          <li>Add the most proven automations so they are ready to start using</li>
          <li>Create your website text widget and show you how to add to your site</li>
          <li>Set up 1 client business account</li>
          <li>Provide a 1 hour training and onboarding session showing you system best practices</li>
        </ul>
        <hr class="ml-sm-5 mr-sm-5">
        <!-- <iframe src="https://player.vimeo.com/video/511322564?title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> -->
        <div class="font-weight-light mt-4" style="font-size: 21px">
          Personalized White Glove Onboarding preventing you from spending hours setting up is <span class="price-span" :class="{'line-through': isWgoCoupon}">${{ formatPrice(wgoPrice) }}</span> <span v-if="isWgoCoupon" class="price-span">${{ formatPrice(this.wgoPriceDiscounted) }}</span> one-time
        </div>
        <div v-if="isWgoCoupon" class="text-center">( Coupon <b>{{ coupon.name }}</b> is applied )</div>
        <div class="mt-4">
          <Button type="button" :classes="`w-100 btn btn-lg btn-primary p-3 font-weight-bold`" :loading="loadingSubscription" @click="addOrderBump()">
            Add White Glove Onboarding so I don't have to set it all up
          </Button>
        </div>
        <div class="mt-4">
            <a class="text-blue h6" @click="skipOrderBump">
              <u>No thanks, I'd like to set everything up myself</u>
            </a>
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import AddCreditCard from '@/components/common/AddCreditCard.vue'
import { stripePlanMetadata } from '@/constants'

export default {
  data () {
    return {
      isLoading: false,
      loadingSetDefault: false,
      loadingPaymentMethod: false,
      selectedPlan: null,
      clientSecret: null,
      isCompletedCard: false,
      loadingSubscription: false,
      isOpenModalConfirm: false,
      currentStep: 1,
      showZapierAlert: false,
      plan: null,
      showOrderBump: false,
      isOpenWgo: false,
      coupon: null,
      wgoPrice: 497,
      activeTabIndex: 0,
      couponCode: undefined,
      couponInfo: undefined,
      processing: false,
    }
  },

  components: {
    AddCreditCard,
  },

  mounted () {
    if (!this.user.stripe_plan)
    {
      this.showOrderBump = true
    }

    if (this.user.stripe_active) {
      this.getPlans()
    } else {
      let planId = this.user.agency.default_plan
      if (this.user.stripe_plan)
        planId = this.user.stripe_plan
      this.getPlan(planId)
    }

    this.initTabIndex(this.userPlan)
  },

  watch: {
    'userPlan': {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue)
        {
          this.initTabIndex(newValue)
        }
      }
    },
  },

  methods: {
    showMetaValue(item) {
      return item && item.value !== 'false' && item.value !== 'true'
    },
    initTabIndex(plan)
    {
      if (plan && plan.interval === 'year')
        this.activeTabIndex = 1
    },
    planPrice(plan) {
      let res = plan.amount_org; 
      if (this.couponInfo) {
        if (this.couponInfo.amount_off)
          res = plan.amount_org - this.couponInfo.amount_off / 100
        else if (this.couponInfo.percent_off)
          res = plan.amount_org * (100 - this.couponInfo.percent_off) / 100
      }
      return res
    },

    applyCoupon() {
      this.couponInfo = undefined
      this.processing = true
      const param = {
        type: 'year',
        code: this.couponCode
      }
      this.$store.dispatch('subscription/getCoupon', param)
        .then((res) => {
          this.couponInfo = res
          this.processing = false
        })
        .catch(errors => {
          this.processing = false
        })
    },
    onCancelWgoModal() {
      this.isOpenWgo = false
      this.handleRedirect()
    },

    formatPrice(value) {
      let val = (value/1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    addOrderBump() {
      this.showOrderBump = false
      const params = {
        coupon: this.coupon ? this.coupon.id: undefined
      }
      this.loadingSubscription = true
      this.$store.dispatch('subscription/orderBump', params)
        .then(() => {
          this.loadingSubscription = false
          this.isOpenWgo = false
          this.handleRedirect();
        })
        .catch(() => {
          this.loadingSubscription = false
      })
    },

    skipOrderBump() {
      this.isOpenWgo = false
      this.showOrderBump = false
      this.handleRedirect();
    },
    
    getPlan(planName) {
      this.isLoading = true
      this.$store.dispatch('subscription/getPlanByName', { name: planName })
        .then((res) => {
          this.plan = res.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
        })
    },

    showMeta(item) {
      return item.value !== 'false'
    },
    
    formatMetadata(value) {
      let metadata = JSON.parse(JSON.stringify(stripePlanMetadata))
      metadata = metadata.map(item => {
        const temp = value.find(item1 => item1.key === item.key)
        return {
          ...item,
          value: temp? temp.value: item.value,
        }
      })
      return metadata;
    },

    formatFeatureValue(value) {
      if (value === 0 || value === '0')
        return 'Unlimited'
      return value
    },

    getPlanLabel(plan) {
      return plan.id == this.user.stripe_plan ? 'Current plan' : (this.user.stripe_active ? 'Change plan' : 'Select Plan')
    },

    onSuccessHandler() {
      if (this.showOrderBump)
      {
        this.isOpenWgo = true
      } else {
        this.handleRedirect()
      }
    },

    onFailedHandler() {
      
    },
    
    onCardFailed() {
      this.loadingSubscription = false
    },

    handleRedirect() {
      if (this.agency && !this.user.has_twilio_setup)
      { 
        this.$router.push({ name: 'agency.landingPage' })
      } else {
        this.getPlans();
      }
    },
    
    handleAddedCard (arg) {
      if (arg) {
        this.coupon = arg.coupon
        let couponId = arg.coupon ? arg.coupon.id: undefined
        if (this.user.has_payment_method) {
          this.loadingSubscription = true
          this.$store.dispatch('subscription/resumeSubscription', {
            userId: this.user.id,
            coupon: couponId
          })
            .then(() => {
              this.loadingSubscription = false
              this.onSuccessHandler()
            })
            .catch(() => {
              this.loadingSubscription = false
              this.getPlans()
            })
        } else {
          this.getPlans()
        }
      }
    },

    setDefaultPaymentMethod (paymentMethodId) {
      this.loadingSetDefault = true
      this.$store.dispatch('subscription/setDefaultPaymentMethod', paymentMethodId)
        .then((res) => {
          this.$store.dispatch('subscription/resumeSubscription', {
            userId: this.user.id
          })
            .then(() => {
              this.loadingSetDefault = false
              this.onSuccessHandler()
            })
            .catch(() => {
              this.loadingSetDefault = false
              this.getPlans()
            })
        })
        .catch((errors) => {
          this.loadingSetDefault = false
        })
    },

    getPlans () {
      this.currentStep = 3
      if (!this.$store.getters['subscription/isLoadedPlan']) {
        this.$store.dispatch('subscription/getPlans')
      }
    },

    getPaymentMethods () {
      this.currentStep = 2
      this.loadingPaymentMethod = true

      this.$store.dispatch('subscription/getPaymentMethods')
        .then(() => this.loadingPaymentMethod = false)
        .catch(err => this.loadingPaymentMethod = false)
    },

    choosePlan (plan) {
      if (!this.user.has_payment_method) {
        this.$router.push({ name: 'agency.paymentMethods' })
        return;
      }
      
      this.selectedPlan = plan
      this.showZapierAlert = false

      if (this.user.stripe_active) {
        if (this.userPlan)
        {
          if (this.user && this.user.subscriptions && this.user.subscriptions.zapier && this.user.subscriptions.zapier.active)
          {
            let selectedPlanZapEnabled = this.formatMetadata(this.selectedPlan.metadata).find(item => item.key === 'Zapier')
            if (selectedPlanZapEnabled && selectedPlanZapEnabled.value === 'false')
            {
              this.showZapierAlert = true
            }
          }
        }
        this.isOpenModalConfirm = true
      } else {
        this.subscription()
      }
    },

    addPaymentMethod () {
      this.currentStep = 1
    },

    subscription () {
      this.loadingSubscription = true

      this.$store.dispatch('subscription/subscriptionPlan', {
        plan: this.selectedPlan.id,
        plan_name: this.selectedPlan.name,
        coupon: this.couponInfo ? this.couponInfo.id : null,
      }).then((res) => {
        this.closeModalConfirm();
        if (this.user.stripe_active)
        {
          this.loadingSubscription = false
          this.onSuccessHandler()
        } else {
          this.$store.dispatch('auth/getProfile').then((res1) => {
            this.loadingSubscription = false
            this.onSuccessHandler()
          }).catch((err) => {
            this.loadingSubscription = false
            this.onSuccessHandler()
          })
        }
      })
      .catch((err) => {
        this.onFailedHandler();
        this.loadingSubscription = false
      })
    },

    closeModalConfirm () {
      this.isOpenModalConfirm = false
      this.couponCode = undefined
      this.couponInfo = undefined
    },
  },

  computed: {
    loading () {
      return this.$store.state.subscription.loading || this.isLoading
    },

    plans () {
      return this.$store.getters['subscription/plans']
    },

    couponEnabled() {
      return this.selectedPlan && this.selectedPlan.interval === 'year'
    },

    yearlyPlans () {
      let list = this.$store.getters['subscription/plans'];
      list = list.filter(item => item.interval === 'year')
      return list
    },

    monthlyPlans () {
      let list = this.$store.getters['subscription/plans'];
      list = list.filter(item => item.interval === 'month')
      return list
    },

    user() {
      return this.$store.getters['auth/user']
    },

    userPlan() {
      let list = this.$store.getters['subscription/plans'];
      let plan = null;
      if (this.user)
      {
        plan = list.find(item => item.id === this.user.stripe_plan)
      }
      return plan
    },

    agency() {
      return this.user && this.user.agency
    },

    paymentMethods () {
      return this.$store.getters['subscription/paymentMethods']
    },

    isWgoCoupon() {
      return this.coupon && this.coupon.meta && this.coupon.meta['WGO'] && this.coupon.meta['WGO'] === 'True'
    },

    wgoPriceDiscounted() {
      let res = this.wgoPrice; 
      if (this.isWgoCoupon) {
        if (this.coupon.amount_off)
          res = this.wgoPrice - this.coupon.amount_off / 100
        else if (this.coupon.percent_off)
          res = this.wgoPrice * (100 - this.coupon.percent_off) / 100
      }
      return res
    },
  },
}
</script>

<style scoped lang="scss">
.subscriptionPage {
  text-align: center;
  h2 {
    font-size: 24px;
    text-align: center;
    max-width: 90%;
    line-height: 35px;
    color: #5f666d;
    margin: auto;
    padding-bottom: 30px;
    span {
      font-size: 30px
    }
  }
  .cart-step {
    background-color: #efefef;
    padding:45px 20px;
    margin: 50px 0;
  }
  .listPaymentMethods {
    padding: 40px 20px; 
  }
  .planDesc {
    display: block;
    max-width: 70%;
    text-align: center;
    margin: auto;
    font-style: italic;
  }
  
  .price-span {
    font-weight: bold;
    font-size: 21px;
    display: inline-block;
    padding: 0 5px;
  }
  .line-through {
    text-decoration: line-through;
  }
  .coupon-wrapper {
    padding: 0;
    margin: 0;
    .coupon-button {
      padding: 0;
      margin: 0;
    }
  }
}
.listPlans .boxPlan {
  display: inline-block;
  margin-right: 2rem;
  padding: 3rem 10px;
  border-radius: 1rem;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  transform: translateY(0);
  transition: transform 250ms ease;
  background: #FFF;
  text-align: center;
  vertical-align: top;
  width: 260px;
  margin-bottom: 30px;
  margin-top: 50px;
}

.listPlans .boxPlan .name {
  color: #00629f;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
}

.listPlans .boxPlan .price {
  color: #009ff2;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px 0px 10px 0px;
  &.price-small {
    font-size: 18px;
  }
}

.listPlans .boxPlan .btnPurchase {
  color: #FFF;
  background-color: #f9bd39;
  border-color: #f9bd39;
  font-size: 14px;
  text-transform: none;
}

.listPlans .boxPlan.active .btnPurchase {
  background-color: #16d409;
  border-color: #16d409;
}

.listPlans .boxPlan:hover {
  transform: translateY(-2px);
}

.listPlans .boxPlan .features {
  margin-top: 20px;
}

.listPlans .boxPlan .features p {
  color: #212529;
  font-size: 15px;
  margin-bottom: 5px;
}

.subscriptionPage .listEmpty {
  text-align: center;
  font-size: 30px;
  color: #666;
  margin-top: 30px;
}

.atom-spinner {
  margin: 0 auto;
}

.loadingBox .atom-spinner{
  margin-top: 100px;
}

.useExistingPayment {
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: center;
  font-weight: 600;
  text-decoration: underline;
  a {
    font-size: 13px;
    color: #3578c6;
  }
}

.listPaymentMethods {
  background: #FFF;
  margin-bottom: 20px;
  padding: 20px;
  .paymentBox {
    border-bottom: 1px dotted #cbd2d6;
    padding: 10px 0px;
    .cardBrand {
      font-size: 16px;
      font-weight: 500;
      color: #2c2e2f;
      text-transform: uppercase;
    }

    .cardNumber, .cardExpiration {
      color: #666;
      font-weight: 500;
    }
  }
}

.listPaymentMethods .paymentBox .actions {
  margin-top: 10px
}

.listPaymentMethods .paymentBox .actions a {
  color: #3578c6;
  display: inline-block;
  margin-right: 10px;
}

.listPaymentMethods .paymentBox .actions a[disabled] {
  opacity: 0.6;
}

.listPaymentMethods .paymentBox .atom-spinner {
  display: inline-block;
}
</style>
